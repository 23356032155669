import RenderComponent from '@/components/modules/DynamicRenderPage';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';

import ParentStyled from './style';

import { loadPageConfig, loadStyle } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/NotInUseVehicles');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.outOfService[0].name}`));
const Content = lazy(() => import(`@/${config.outOfService[1].name}`));
const TopActions =
  config.outOfService[0].children &&
  lazy(() => import(`${config.outOfService[0].children.name}`));

const NotInUseVehicles = (parentProps) => {
  const { fetchNotInUseVehicles, history, i18n, theme } = parentProps;
  const [pagination, setPagination] = useState(false);

  const match = useParams();
  const commonParentProps = { history, i18n, Styled, theme };

  useEffect(() => {
    fetchNotInUseVehicles({
      apiId: 'fetchNotInUseVehicles',
      data: 'OUTOFSERVICE'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config.vehicleNotInUse[0]}
        parentProps={{ ...parentProps, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        header={`dashboard.notInUseVehicles.pageHeading.OUTOFSERVICE`}
      />
      <RenderComponent
        {...config.vehicleNotInUse[1]}
        parentProps={{ ...parentProps, pagination, setPagination }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    notInUseVehicles: state.notInUseVehicles
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchNotInUseVehicles: (data) => dispatch.notInUseVehicles.fetchData(data),
    filterSuccess: (data) => dispatch.notInUseVehicles.filterSuccess(data)
  };
};

export default connect(mapState, mapDispatch)(NotInUseVehicles);
NotInUseVehicles.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetchNotInUseVehicles: PropTypes.func.isRequired,
  notInUseVehicles: PropTypes.array.isRequired
};
