import { loadSvg } from '@/utils/utils';
import PropTypes from 'prop-types';
import React from 'react';
const layout = window?.$environment?.CURRENT_LAYOUT;
const SvgIcon = ({ value, onClick }) => {
  const isEmbeded =  window.$environment?.EMBED_URL && window.location.href.includes(window.$environment?.EMBED_URL);

  if (
    (value === 'pageBackButton.svg' && (layout === 'SpireonNew' || isEmbeded)) ||
    value !== 'pageBackButton.svg'
  ) {
    const images = loadSvg();
    const Component = images[value.replace('.svg', '')];
    return <Component onClick={onClick} />;
  } else {
    return null;
  }
};

SvgIcon.propTypes = {
  value: PropTypes.string.isRequired
};

export default SvgIcon;
