import { ReactComponent as Accident } from '../../../assets/riskTheory/icons/accident.svg';
import { ReactComponent as Company } from '../../../assets/riskTheory/icons/company.svg';
import { ReactComponent as DotAudit } from '../../../assets/riskTheory/icons/dotAudit.svg';
import { ReactComponent as Filter } from '../../../assets/riskTheory/icons/filter.svg';
import { ReactComponent as Inspections } from '../../../assets/riskTheory/icons/inspection.svg';
import { ReactComponent as Logs } from '../../../assets/riskTheory/icons/logs.svg';
import { ReactComponent as Policy } from '../../../assets/riskTheory/icons/policy.svg';
import { ReactComponent as Repair } from '../../../assets/riskTheory/icons/repair.svg';
import { ReactComponent as Usdot } from '../../../assets/riskTheory/icons/usdot.svg';
import { ReactComponent as User } from '../../../assets/riskTheory/icons/user.svg';
import { ReactComponent as Vehicle } from '../../../assets/riskTheory/icons/vehicle.svg';
// import { ReactComponent as Documents } from '../../../assets/riskTheory/icons/document.svg';
import { ReactComponent as infoTruck } from '../../../assets/commonSVG/infoTruck.svg';
import { ReactComponent as Account } from '../../../assets/riskTheory/icons/account.svg';
import { ReactComponent as Alerts } from '../../../assets/riskTheory/icons/alert.svg';
import { ReactComponent as BobTail } from '../../../assets/riskTheory/icons/bobTail.svg';
import { ReactComponent as BoxTruck } from '../../../assets/riskTheory/icons/boxTruck.svg';
import { ReactComponent as CSA } from '../../../assets/riskTheory/icons/csa.svg';
import { ReactComponent as CsaSnapshot } from '../../../assets/riskTheory/icons/csaSnapshot.svg';
import { ReactComponent as DailyDocuments } from '../../../assets/riskTheory/icons/dailyDocuments.svg';
import { ReactComponent as DateIcon } from '../../../assets/riskTheory/icons/date.svg';
import { ReactComponent as Departments } from '../../../assets/riskTheory/icons/departments.svg';
import { ReactComponent as Developer } from '../../../assets/riskTheory/icons/developer.svg';
import { ReactComponent as DOT } from '../../../assets/riskTheory/icons/dot.svg';
import { ReactComponent as DriverStatus } from '../../../assets/riskTheory/icons/driver.svg';
import { ReactComponent as DryVan } from '../../../assets/riskTheory/icons/dryVan.svg';
import { ReactComponent as Dump } from '../../../assets/riskTheory/icons/dump.svg';
import { ReactComponent as Es } from '../../../assets/riskTheory/icons/es.svg';
import { ReactComponent as Feedback } from '../../../assets/riskTheory/icons/feedback.svg';
import { ReactComponent as FlatBed } from '../../../assets/riskTheory/icons/flatBed.svg';
import { ReactComponent as Fleet } from '../../../assets/riskTheory/icons/fleet.svg';
import { ReactComponent as Fuel } from '../../../assets/riskTheory/icons/fuel.svg';
import { ReactComponent as Ifta } from '../../../assets/riskTheory/icons/ifta.svg';
import { ReactComponent as Location } from '../../../assets/riskTheory/icons/location.svg';
import { ReactComponent as Logout } from '../../../assets/riskTheory/icons/logout.svg';
import { ReactComponent as Messaging } from '../../../assets/riskTheory/icons/messaging.svg';
import { ReactComponent as OtherTrailer } from '../../../assets/riskTheory/icons/otherTrailer.svg';
import { ReactComponent as OtherVehicle } from '../../../assets/riskTheory/icons/otherTruck.svg';
import { ReactComponent as Overview } from '../../../assets/riskTheory/icons/overview.svg';
import { ReactComponent as Pickup } from '../../../assets/riskTheory/icons/pickup.svg';
import { ReactComponent as PneumaticTrailer } from '../../../assets/riskTheory/icons/pneumaticTrailer.svg';
import { ReactComponent as PrivatePassenger } from '../../../assets/riskTheory/icons/privatePassenger.svg';
import { ReactComponent as ReeferTrailer } from '../../../assets/riskTheory/icons/reeferTrailer.svg';
import { ReactComponent as Reports } from '../../../assets/riskTheory/icons/reports.svg';
import { ReactComponent as unsort } from '../../../assets/riskTheory/icons/sort.svg';
import { ReactComponent as StraightTruck } from '../../../assets/riskTheory/icons/straightTruck.svg';
import { ReactComponent as Switch } from '../../../assets/riskTheory/icons/switch.svg';
import { ReactComponent as TankerTrailer } from '../../../assets/riskTheory/icons/tankerTrailer.svg';
import { ReactComponent as ToggleOff } from '../../../assets/riskTheory/icons/toggle-off.svg';
import { ReactComponent as ToggleOn } from '../../../assets/riskTheory/icons/toggle-on.svg';
import { ReactComponent as TractorDayCab } from '../../../assets/riskTheory/icons/tractorDayCab.svg';
import { ReactComponent as TractorSleeper } from '../../../assets/riskTheory/icons/tractorSleeper.svg';
import { ReactComponent as VacuumTrailer } from '../../../assets/riskTheory/icons/vacuumTrailer.svg';
import { ReactComponent as VacuumTruck } from '../../../assets/riskTheory/icons/vacuumTruck.svg';
import { ReactComponent as Van } from '../../../assets/riskTheory/icons/van.svg';
import { ReactComponent as Violation } from '../../../assets/riskTheory/icons/violation.svg';
import baseSvgs from '../base';

const svgs = {
  ...baseSvgs,
  logs: Logs,
  vehicle: Vehicle,
  accident: Accident,
  user: User,
  repair: Repair,
  inspection: Inspections,
  filter: Filter,
  company: Company,
  usdot: Usdot,
  dotAudit: DotAudit,
  policy: Policy,
  departments: Departments,
  ifta: Ifta,
  reports: Reports,
  developer: Developer,
  feedback: Feedback,
  // documents: Documents,
  dailyDocuments: DailyDocuments,
  fuel: Fuel,
  csa: CSA,
  unsort,
  account: Account,
  es: Es,
  switch: Switch,
  messaging: Messaging,
  logout: Logout,
  alerts: Alerts,
  overview: Overview,
  csaSnapshot: CsaSnapshot,
  fleet: Fleet,
  location: Location,
  dot: DOT,
  date: DateIcon,
  bobTail: BobTail,
  boxTruck: BoxTruck,
  dryVan: DryVan,
  dump: Dump,
  flatBed: FlatBed,
  driverStatus: DriverStatus,
  violation: Violation,
  straightTruck: StraightTruck,
  otherTrailer: OtherTrailer,
  otherVehicle: OtherVehicle,
  pickup: Pickup,
  pneumaticTrailer: PneumaticTrailer,
  privatePassenger: PrivatePassenger,
  reeferTrailer: ReeferTrailer,
  tankerTrailer: TankerTrailer,
  tractorDayCab: TractorDayCab,
  tractorSleeper: TractorSleeper,
  vacuumTrailer: VacuumTrailer,
  vacuumTruck: VacuumTruck,
  van: Van,
  toggleOn: ToggleOn,
  toggleOff: ToggleOff,
  infoTruck,
};
export default svgs;
