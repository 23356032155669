let eldX_reseller = window.location.pathname.split("/")[1] || sessionStorage.getItem("eldX_reseller")

export default {
  'dev.gorillasafety.com': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://dev.gorillasafety.com',
    BE_BASE_URL: 'https://dev.gorillasafety.com/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },

  'clutch.dev.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://clutch.dev.gorillasafety.org',
    BE_BASE_URL: 'https://clutch.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'clutch',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },
  'eldmandate.dev..gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://eldmandate.dev..gorillasafety.org',
    BE_BASE_URL: 'https://eldmandate.dev..gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'em',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },
  'spireon.dev.gorillasafety.org': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireon.dev.gorillasafety.org',
    BE_BASE_URL: 'https://spireon.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },
  'truckerpath.dev.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://truckerpath.dev.gorillasafety.org',
    BE_BASE_URL: 'https://truckerpath.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'tp',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    ZENDESK_KEY: '8f409486-f4c4-4a8a-a45c-3be20c7627e1',
  },
  'trackon.dev.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://trackon.dev.gorillasafety.org',
    BE_BASE_URL: 'https://trackon.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'trackon',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    REG_LINK: 'https://dev.gorillasafety.com/registration/step1',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },
  'cyntrx.dev.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrx.dev.gorillasafety.org',
    BE_BASE_URL: 'https://cyntrx.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'cyntrx',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p'
  },
  'cyntrxdvir.dev.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrxdvir.dev.gorillasafety.org',
    BE_BASE_URL: 'https://cyntrxdvir.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'edvir',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
  },
  'webfleet.dev.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://webfleet.dev.gorillasafety.org',
    BE_BASE_URL: 'https://webfleet.dev.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'webfleet',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    clutchNewUI: true
  },
  'eldx.gorillasafety.com': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://eldx.gorillasafety.com',
    S3_URL: `https://eldx-reseller-staging.s3.amazonaws.com/${eldX_reseller}`,
    BE_BASE_URL: `https://eldx.gorillasafety.com/api`,
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'eldx',
    RESELLER: '/' + eldX_reseller,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p'
  },
  // 'fleettrack.gorillasafety.org': {
  //   APP_LAYOUT: 'Clutch',
  //   BASE_URL: 'https://fleettrack.gorillasafety.org',
  //   BE_BASE_URL: 'https://fleettrack.gorillasafety.org/api',
  //   COOKIE_EXPIRY: 1,
  EMBED_URL: '/embed',
  //   WHITE_THEME: true,
  //   SERVICE_PROVIDER: 'ft',
  //   GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
  //   BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
  //   SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p'
  // },
  'fleettrack.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://fleettrack.gorillasafety.org',
    BE_BASE_URL: 'https://fleettrack.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    EMBED_URL: '/embed',
    SERVICE_PROVIDER: 'hcss',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p'
  },
};
