import PropTypes from 'prop-types';
import React, { lazy } from 'react';

import { loadStyle } from '@/utils/utils';
import { useHistory } from 'react-router-dom';
import ParentStyled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
let Styled = loadStyle(ParentStyled, 'components/Title');
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));
const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));

const Label = lazy(() => import(`../UI/Label/${layout}`));
const Title = ({
  header,
  i18n,
  children,
  vertical,
  searchConfig,
  withBackground,
  withBackgroundPolicy,
  noBackbutton
}) => {
  const history = useHistory();
  const selectChange = (e) => {
    if (fromData || isSpireonNew) filterSuccess(e);
    else changeRequest({ search: e });
    setPagination(true);
  };
  const {
    filterSuccess,
    setPagination,
    searchPlaceholder,
    data,
    query,
    changeRequest,
    fromData,
    isSpireonNew
  } = { ...(searchConfig || {}) };

  const isEmbeded =  window.$environment?.EMBED_URL && window.location.href.includes(window.$environment?.EMBED_URL);

  return (
    <Styled.HeaderContainer vertical={vertical} withBackground={withBackground} withBackgroundPolicy={withBackgroundPolicy}>
      <div id="container" className={children ? 'pageSpecific' : ''}>
        {isEmbeded ? 
          <div className='goBackEmbeded'>
            <SvgIcon value={'pageBackButton.svg'} className="goBack" onClick={() => history.goBack()} />
          </div> : 
          <div className={isEmbeded ? 'goBack' : noBackbutton ? 'hideGoBack':'goBack'}>
          <SvgIcon value={'pageBackButton.svg'} className="goBack" onClick={() => history.goBack()} />
        </div>}
        {header && typeof header === 'string' && !searchConfig ? (
          <Label variant="h1" className={isEmbeded ? "embed componentChange" : "componentChange"}>
            {i18n.t(header)}
          </Label>
        ) : (
          header && typeof header === 'function' && header()
        )}
        {window.$environment.CURRENT_LAYOUT === 'SpireonNew' && searchConfig && (
          <div className="searchWrapper">
            <Label variant="h1" className="componentChange">
              {i18n.t(header)}
            </Label>
            <Search
              id={'searchBox'}
              onChange={(e) => selectChange(e)}
              default={data || []}
              fromData={fromData}
              placeHolder={searchPlaceholder}
              query={query ? query : []}
            />
          </div>
        )}
        {children && children}
      </div>
    </Styled.HeaderContainer>
  );
};

export default Title;
Title.defaultProps = {
  header: ''
};
Title.propTypes = {
  header: PropTypes.string
};
