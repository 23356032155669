import { ReactComponent as Accident } from '../../../assets/gs/OldUi/icons/accident.svg';
import { ReactComponent as Account } from '../../../assets/gs/OldUi/icons/account.svg';
import { ReactComponent as Company } from '../../../assets/gs/OldUi/icons/company.svg';
import { ReactComponent as CSA } from '../../../assets/gs/OldUi/icons/csa.svg';
import { ReactComponent as DailyDocuments } from '../../../assets/gs/OldUi/icons/dailyDocuments.svg';
import { ReactComponent as Departments } from '../../../assets/gs/OldUi/icons/departments.svg';
import { ReactComponent as Developer } from '../../../assets/gs/OldUi/icons/developer.svg';
import { ReactComponent as Documents } from '../../../assets/gs/OldUi/icons/documents.svg';
import { ReactComponent as DotAudit } from '../../../assets/gs/OldUi/icons/dotAudit.svg';
import { ReactComponent as Es } from '../../../assets/gs/OldUi/icons/es.svg';
import { ReactComponent as Feedback } from '../../../assets/gs/OldUi/icons/feedback.svg';
import { ReactComponent as Filter } from '../../../assets/gs/OldUi/icons/filter.svg';
import { ReactComponent as Fuel } from '../../../assets/gs/OldUi/icons/fuel.svg';
import { ReactComponent as Ifta } from '../../../assets/gs/OldUi/icons/ifta.svg';
import { ReactComponent as Inspections } from '../../../assets/gs/OldUi/icons/inspection.svg';
import { ReactComponent as Logout } from '../../../assets/gs/OldUi/icons/logout.svg';
import { ReactComponent as Logs } from '../../../assets/gs/OldUi/icons/logs.svg';
import { ReactComponent as Messaging } from '../../../assets/gs/OldUi/icons/messaging.svg';
import { ReactComponent as Policy } from '../../../assets/gs/OldUi/icons/policy.svg';
import { ReactComponent as Repair } from '../../../assets/gs/OldUi/icons/repair.svg';
import { ReactComponent as Reports } from '../../../assets/gs/OldUi/icons/reports.svg';
import { ReactComponent as unsort } from '../../../assets/gs/OldUi/icons/sort.svg';
import { ReactComponent as Switch } from '../../../assets/gs/OldUi/icons/switch.svg';
import { ReactComponent as ToggleOff } from '../../../assets/gs/OldUi/icons/toggle-off.svg';
import { ReactComponent as ToggleOn } from '../../../assets/gs/OldUi/icons/toggle-on.svg';
import { ReactComponent as Usdot } from '../../../assets/gs/OldUi/icons/usdot.svg';
import { ReactComponent as User } from '../../../assets/gs/OldUi/icons/user.svg';
import { ReactComponent as Vehicle } from '../../../assets/gs/OldUi/icons/vehicle.svg';
import baseSvgs from '../base';

const svgs = {
  ...baseSvgs,
  logs: Logs,
  vehicle: Vehicle,
  accident: Accident,
  user: User,
  repair: Repair,
  inspection: Inspections,
  filter: Filter,
  company: Company,
  usdot: Usdot,
  dotAudit: DotAudit,
  policy: Policy,
  departments: Departments,
  ifta: Ifta,
  reports: Reports,
  developer: Developer,
  feedback: Feedback,
  documents: Documents,
  dailyDocuments: DailyDocuments,
  fuel: Fuel,
  csa: CSA,
  unsort,
  account: Account,
  es: Es,
  switch: Switch,
  messaging: Messaging,
  logout: Logout,
  toggleOn: ToggleOn,
  toggleOff: ToggleOff
};
export default svgs;
